import { memo } from "react";
import { Helmet } from "react-helmet-async";

export interface HeadProps {
  title?: string;
  description?: string;
}
export const Head = memo(({ title, description }: HeadProps) => {
  return (
    <Helmet>
      <title>
        {title
          ? "2023 K-Digital Training | " + title
          : "2023 K-Digital Training"}
      </title>
      <meta
        name="description"
        content={
          description ||
          "AI/빅데이터를 활용한 사이버보안 위협 탐지기술 개발자 양성과정"
        }
      />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta property="og:title" content={title || "2023 K-Digital Traning"} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="nc.pnp.cmcom.kr" />
      <meta property="og:article:author" content="컬처메이커스" />
    </Helmet>
  );
});
