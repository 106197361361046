import { Link } from "react-router-dom";
import { Head } from "../../base/Head";

const Apply = () => {
  return (
    <>
      <Head title="신청하기" />
      <div className="relative">
        <img src="assets/page/apply.webp" />
        <Link
          to="https://buly.kr/YbBi1B"
          target="_blank"
          className="absolute bottom-[77px] left-[74px]"
        >
          <img className="w-[850px]" src="assets/button/register-button.png" />
        </Link>
      </div>
    </>
  );
};

export default Apply;
