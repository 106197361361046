import { Head } from "../../base/Head";

const Plan = () => {
  return (
    <>
      <Head title="교육일정" />
      <img src="assets/page/plan.webp" />
    </>
  );
};

export default Plan;
