import { useEffect, memo } from "react";
import { useLocation, Outlet, Link } from "react-router-dom";

export const PageTemplate = memo(() => {
  const { pathname } = useLocation();
  return (
    <div className="mx-auto w-[1024px]">
      <div className="flex flex-row h-20">
        <Link to="/" className="w-32">
          {pathname === "/" ? (
            <img src="assets/button/home-selected.png" />
          ) : (
            <img src="assets/button/home-unselected.png" />
          )}
        </Link>
        <Link to="/info" className="w-56">
          {pathname === "/info" ? (
            <img src="assets/button/info-selected.png" />
          ) : (
            <img src="assets/button/info-unselected.png" />
          )}
        </Link>
        <Link to="/plan" className="w-56">
          {pathname === "/plan" ? (
            <img src="assets/button/plan-selected.png" />
          ) : (
            <img src="assets/button/plan-unselected.png" />
          )}
        </Link>
        <Link to="/apply" className="w-56">
          {pathname === "/apply" ? (
            <img src="assets/button/apply-selected.png" />
          ) : (
            <img src="assets/button/apply-unselected.png" />
          )}
        </Link>
        <Link to="/cop" className="w-56">
          {pathname === "/cop" ? (
            <img src="assets/button/cop-selected.png" />
          ) : (
            <img src="assets/button/cop-unselected.png" />
          )}
        </Link>
      </div>
      <Outlet />
      <img src="assets/page/footer.webp" />
    </div>
  );
});
