import { Head } from "../../base/Head";

const Info = () => {
  return (
    <>
      <Head title="과정소개" />
      <img src="assets/page/info.webp" />
    </>
  );
};

export default Info;
