import { Head } from "../../base/Head";

const Cop = () => {
  return (
    <>
      <Head title="협약기업" />
      <img src="assets/page/cop.webp" />
    </>
  );
};

export default Cop;
