import { Routes, Route } from "react-router-dom";
import { PageTemplate } from "./components/base/PageTemplate";
import Info from "./components/pages/Info";
import Plan from "./components/pages/Plan";
import Apply from "./components/pages/Apply";
import Cop from "./components/pages/Cop";
import Home from "./components/pages/Home";
import Error404 from "./components/pages/Error/404";

function App() {
  return (
    <Routes>
      <Route path="/" element={<PageTemplate />}>
        <Route index element={<Home />} />
        <Route path="/info" element={<Info />} />
        <Route path="/plan" element={<Plan />} />
        <Route path="/apply" element={<Apply />} />
        <Route path="/cop" element={<Cop />} />
      </Route>
      <Route path="/*" element={<Error404 />} />
    </Routes>
  );
}

export default App;
